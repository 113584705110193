<template>
  <div class="about">
    <h1>Futur 21 - Zukunft der Arbeit</h1>
    Prototype by
    <a href="https://www.patrik-huebner.com" target="_blank"
      >Patrik H&uuml;bner</a
    ><br />
    FH Bielefeld, Fachbereich Gestaltung
    <p />
    To move around: Use the arrow keys or WASD<br />
    To run: Press the SHIFT-key<br />
    To trigger an action: Press the SPACE-key
  </div>
</template>


<style lang="scss">
.about {
  background: #000;
  padding: 40px;

  a, a:visited {
    color: #fff;
  }
}
</style>
